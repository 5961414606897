<template>
  <div class="scoreEvaluation">
    <div v-if="ifList">
      <div class="tabs">
        <div class="tabs-item" :class="search == 0 ? 'on' : ''" @click="searchClick(0)">
          全部答疑
        </div>
        <div class="tabs-item" :class="search == 1 ? 'on' : ''" @click="searchClick(1)">
          我的回答
        </div>
        <div class="tabs-item" :class="search == 2 ? 'on' : ''" @click="searchClick(2)">
          我的提问
        </div>
      </div>
      <div class="coupon-content">
        <div class="coupon-title">答疑提问</div>

        <el-input v-model="formData.content" type="textarea" :rows="4" maxlength="200" show-word-limit
          placeholder="请输入内容..." />
        <div class="flex uploadBpx">
          <div class="w-b-100 flex-end">
            <el-upload ref="upload" :action="imgAction()" :headers="Domainjwt" name="upload" :limit="3"
              list-type="picture-card" :before-upload="beforeAvatarUpload" :on-success="handleAvatarSuccess"
              :on-remove="handleRemove">
              <i class="el-icon-plus" />
            </el-upload>
            <div class="tips">
              <span class="num">{{
                formData.imgList ? formData.imgList.length : 0
              }}</span>/3 点击上传图片（最多可上传3张，每张小于2M）
            </div>
          </div>
          <div class="keep-learn keep-check shrink0" @click.stop="send()">
            确认提交
          </div>
        </div>
      </div>
      <div class="soreList">
        <div v-for="(item, index) in list" :key="index" class="sore-item">
          <img class="userImg" :src="item.stuImg" alt="">
          <div class="sore-content" @click="open(item)">
            <div class="userName">{{ item.stuName }}</div>
            <div class="addTime">{{ item.addTime }}</div>
            <div class="content">{{ item.content }}</div>
            <div class="flex-c m-t-24">
              <img v-for="(img, imgIndex) in item.img" :key="imgIndex" class="reply-img m-r-8" :src="img"
                mode="scaleToFill" @click="ViewImage(img)">
            </div>
            <div v-if="item.replyList && item.replyList.length > 0" class="replyList">
              <div v-for="(reply, replyIndex) in item.replyList" :key="replyIndex" class="reply-item">
                <div v-if="item.isOpen || replyIndex < 3">
                  <div class="dis_flex">
                    <span :class="reply.replyUserId == userInfo.id || reply.status == 2
                        ? 'color-FF5D51-i '
                        : ''
                      " class="name-span">{{
    reply.replyUserId == userInfo.id
    ? "我"
    : reply.replyUserName
  }}</span>
                    :
                    <!-- <span class="name-type" v-if="reply.status == 2">老师</span> -->
                    <span class="name-content m-l-8">{{
                      reply.replyContent
                    }}</span>
                    <img v-if="reply.isOptimum == 1" class="optimal" src="@/assets/img/Home/optimal.png">
                  </div>
                  <div v-if="reply.replyImg && reply.replyImg.length > 0" class="flex-c m-t-24">
                    <img v-for="(img, imgIndex) in reply.replyImg" :key="imgIndex" class="reply-img m-r-8" :src="img"
                      mode="scaleToFill" @click="ViewImage(img)">
                  </div>
                </div>
              </div>
              <div v-if="item.replyList.length > 3" class="reply-more m-t-24 color-FF5D51-i"
                :class="item.isOpen ? 'open' : ''" @click.stop="repalyOpen(item)">
                {{
                  !item.isOpen ? `查看全部${item.replyList.length}条` : "收起"
                }}
                <i class="iconfont icon-gengduo" />
              </div>
            </div>
            <div class="replyClick">
              <i class="iconfont icon-Frame" /><el-button type="text" class="info">回复</el-button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!list || list.length == 0" class="nothing">
        <div class="nothing-span">暂无答疑哦~</div>
      </div>
      <el-pagination class="pagination" :total="total" :current-page="pageNum" :page-sizes="[5, 10, 15, 40]"
        :page-size="pageSize" :pager-count="5" background layout="total, sizes, prev, pager, next"
        @current-change="handleCurrentChange" @size-change="handleSizeChange" />
    </div>
    <el-dialog custom-class="myDialog" :visible.sync="ifCoupon" width="700px" @close="clode">
      <div class="coupon-content">
        <div class="coupon-title">
          {{ formData.stuName ? "回复：" + formData.stuName : "答疑" }}
        </div>

        <el-input v-model="dialog.content" type="textarea" :rows="4" maxlength="200" show-word-limit
          placeholder="请输入内容..." />
        <div class="uploadBpx">
          <div class="w-b-100 flex-end">
            <el-upload ref="uploadReply" :action="imgAction()" name="upload" :headers="Domainjwt" :limit="3"
              list-type="picture-card" :on-success="dialogSuccess" :on-remove="dialogRemove">
              <i class="el-icon-plus" />
            </el-upload>
            <div class="tips">
              <span class="num">{{
                dialog.imgList ? dialog.imgList.length : 0
              }}</span>/3 点击上传图片（最多可上传3张，每张小于2M）
            </div>
          </div>
        </div>
        <div class="flex-e">
          <div class="keep-learn keep-check" @click.stop="send()">确认提交</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo, getDomainToken } from '@/api/cookies'
import { Evaluation } from '@/api/scoreEvaluation'
const evaluation = new Evaluation()
export default {
  props: {
    type: {
      type: Number // /type	 1课程  2试卷
    },
    IsPurchase: {
      type: Boolean
    },
    ifList: {
      default: true,
      type: Boolean
    },
    courseId: {
      // 产品id（课程  试卷）
      type: String | Number
    },
    chapterId: {
      // id（课程章节   试卷试题）
      type: String | Number
    },
    status: {
      // 1直播 2录播  3 音频 4试卷
      type: String | Number
    }
  },
  data() {
    return {
      pageSize: 5,
      pageNum: 1,
      total: 0,
      list: [],
      userInfo: null,
      /* 提交答疑 */
      ifCoupon: false,
      thisList: null,
      search: 0,
      Domainjwt: {
        Domainjwt: getDomainToken()
      },
      formData: {
        questionsId: null,
        stuName: null,
        content: '',
        imgList: []
      },
      canClick: true,
      dialog: {
        questionsId: null,
        stuName: null,
        content: '',
        imgList: []
      }
    }
  },
  async created() {
    this.userInfo = getInfo()
    this.ifCoupon = !this.ifList
    setTimeout(() => {
      this.getList()
    }, 1000)
    // await
  },
  methods: {
    searchClick(val) {
      if (val) {
        const userInfo = getInfo()
        if (!userInfo) {
          Vue.prototype.goLoginView(false)
        }
      }
      this.search = val
      this.pageSize = 5
      this.pageNum = 1
      this.list = []
      this.getList()
    },
    getList() {
      evaluation
        .selectStuQuestionsList(
          this.pageSize,
          this.pageNum,
          this.type,
          this.courseId,
          this.status,
          this.chapterId,
          this.search == 1
            ? this.userInfo
              ? this.userInfo.id
              : undefined
            : undefined,
          this.search == 2
            ? this.userInfo
              ? this.userInfo.id
              : undefined
            : undefined
        )
        .then((res) => {
          for (const item of res.rows) {
            if (item.img) {
              item.img = item.img.split(',')
            }
            if (item.replyList) {
              for (const reply of item.replyList) {
                if (reply.replyImg) {
                  reply.replyImg = reply.replyImg.split(',')
                }
              }
            }
          }
          /*    if (this.pageNum == 1) {
            this.list = res.rows;
          } else {
            // 到底加载拼接
            this.list = this.list.concat(res.rows);
          } */
          this.list = res.rows
          this.total = res.total
        })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getList()
    },
    beforeAvatarUpload(file) {
      const allowImgType = ['image/jpeg', 'image/png', 'image/gif']
      if (!allowImgType.includes(file.type)) {
        this.$message.error('上传文件类型不符,仅支持jpg/png/gif格式图片')
        return false
      }
    },

    /* =======================发送答疑======================== */
    // 图片上传
    handleAvatarSuccess(res, file, fileList) {
      if (res.isSuccess) {
        if (!this.formData.imgList) {
          this.formData.imgList = []
        }
        const data = { url: res.path, path: '' }
        this.formData.imgList.push(data)
      }
    },
    dialogSuccess(res, file, fileList) {
      console.log('上传成功')
      if (res.isSuccess) {
        if (!this.dialog.imgList) {
          this.dialog.imgList = []
        }
        const data = { url: res.path, path: '' }
        this.dialog.imgList.push(data)
      }
    },
    /* 移除2 */
    handleRemove(file, fileList) {
      this.formData.imgList = this.formData.imgList.filter((item) => {
        return item.url != file.response.path
      })
      this.$forceUpdate()
    },
    dialogRemove(file, fileList) {
      console.log('删除成功')
      this.dialog.imgList = this.dialog.imgList.filter((item) => {
        return item.url != file.response.path
      })
      this.$forceUpdate()
    },
    /* 提交 */
    send() {
      if (!this.canClick) {
        return
      }
      this.canClick = false
      const userInfo = getInfo()
      if (!userInfo) {
        Vue.prototype.goLoginView(false)
        return
      }
      if (!this.IsPurchase) {
        this.$message({
          showClose: true,
          message: '请先购买课程!',
          type: 'warning'
        })
        this.canClick = true
        return
      }
      let imgList = []
      if (this.formData.questionsId || !this.ifList) {
        if (!this.dialog.content) {
          this.$message({
            showClose: true,
            message: '提交内容不能为空!',
            type: 'warning'
          })
          this.canClick = true
          return
        }
        imgList = this.dialog.imgList.map((item) => {
          return item.url
        })
      } else {
        if (!this.formData.content) {
          this.$message({
            showClose: true,
            message: '提交内容不能为空!',
            type: 'warning'
          })
          this.canClick = true
          return
        }
        imgList = this.formData.imgList.map((item) => {
          return item.url
        })
      }
      /* 回复 */
      if (this.ifCoupon) {
        if (this.ifList) {
          evaluation
            .replyStuQuestions(
              this.formData.questionsId,
              this.userInfo.img,
              this.dialog.content,
              imgList.join(',')
            )
            .then((res) => {
              if (res.code == 0) {
                this.ifCoupon = false
                this.$emit('close')

                this.getList()
                this.formData = {
                  questionsId: null,
                  stuName: null,
                  content: '',
                  imgList: []
                }
                this.$refs.uploadReply.clearFiles()
              }
            })
        } else {
          evaluation
            .saveQuestions(
              this.type,
              this.status,
              this.courseId,
              this.chapterId,
              this.userInfo.account,
              this.userInfo.img,
              this.dialog.content,
              imgList.join(',')
            )
            .then((res) => {
              if (res.code == 0) {
                this.ifCoupon = false
                this.$emit('close')
                this.getList()
                this.formData = {
                  questionsId: null,
                  stuName: null,
                  content: '',
                  imgList: []
                }
              }
              this.$refs.upload.clearFiles()
            })
        }
      } else {
        evaluation
          .saveQuestions(
            this.type,
            this.status,
            this.courseId,
            this.chapterId,
            this.userInfo.account,
            this.userInfo.img,
            this.formData.content,
            imgList.join(',')
          )
          .then((res) => {
            if (res.code == 0) {
              this.ifCoupon = false
              this.$emit('close')
              this.getList()
              this.formData = {
                questionsId: null,
                stuName: null,
                content: '',
                imgList: []
              }
            }
            this.$refs.upload.clearFiles()
          })
      }
      this.canClick = true
    },
    /* 打开回复弹窗 */
    open(item) {
      if (item.isClose == 1) {
        this.$notify({
          title: '错误',
          message: '此内容不可回复',
          type: 'error',
          duration: 2000
        })
        return
      }
      if (item) {
        this.formData.questionsId = item.id
        this.formData.stuName = item.stuName
      }
      this.dialog.content = ''
      this.dialog.imgList = []
      this.ifCoupon = true
    },
    /* 打开回复 */
    repalyOpen(reply) {
      if (reply.isOpen) {
        reply.isOpen = false
      } else {
        reply['isOpen'] = true
      }
      this.$forceUpdate()
    },
    clode() {
      this.$emit('close')
    }
  }
}
</script>
<style lang="less" scoped>
/* 标签 */
.tabs {
  display: flex;
  padding: 32px 32px 16px;

  .tabs-item {
    width: 112px;
    height: 40px;
    background: #eeeeee;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 16px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #666666;
    line-height: 38px;
    text-align: center;
    margin-right: 20px;
    border: 1px solid #eeeeee;
    cursor: pointer;
  }

  .on {
    background: #ffedeb;
    border: 1px solid #ff5d51;
    color: #ff5d51;
  }
}

/* 内容 */
.soreList {
  padding: 0px 32px;
  width: 100%;

  .sore-item {
    width: 100%;
    display: flex;
    padding: 32px 0px;
    border-bottom: 1px solid #f7f7f7;
    position: relative;

    .optimal {
      width: 65px;
      height: 22px;
      margin-left: 8px;
      /*   position: absolute;
      top: 0px; */
    }

    .userImg {
      width: 48px;
      height: 48px;

      border-radius: 50%;
      margin-right: 16px;
      flex-shrink: 0;
    }

    .sore-content {
      width: 100%;

      .userName {
        height: 16px;
        font-size: 16px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        margin-top: 5px;
      }

      .addTime {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-top: 8px;
      }

      .content {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #666666;
        line-height: 16px;
        margin-top: 21px;
      }

      /* 回复列表 */
      .replyList {
        width: 100%;
        background: #f5f7f9;
        border-radius: 8px;
        padding: 20px;
        margin-top: 20px;
        transition: height 1s;

        // height: 100%;
        .reply-item {
          margin-top: 12px;
          font-size: 16px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #666666;
          line-height: 24px;
          position: relative;

          .name-span {
            color: #373a42;
            flex-shrink: 0;
          }

          .name-tips {
            margin: 0px 8px;
          }

          .name-type {
            padding: 0px 4px;
            height: 28px;
            background: #ff5d51;
            border-radius: 4px 4px 4px 4px;

            text-align: center;
            font-size: 20px;
            font-family: PingFang SC-Regular;
            font-weight: 400;
            color: #ffffff;
            line-height: 28px;
            margin: 0px 8px;
          }
        }

        .reply-more {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular;
          font-weight: 400;
          color: #ff5e51;
          line-height: 14px;
          cursor: pointer;
          display: flex;
          align-items: center;

          .iconfont {
            color: #ff5d51;
            font-size: 14px;
            line-height: 14px;
            margin: 0px 8px;
            transition: transform 0.4s;
            transform: rotate(0deg);
          }
        }

        .open {
          .iconfont {
            transform: rotate(-90deg);
            transition: transform 0.4s;
            display: inline-block;
          }
        }
      }

      /* 回复 */
      .replyClick {
        height: 12px;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 12px;
        margin-top: 16px;

        .iconfont {
          font-size: 12px;
          color: #999999;
          margin-right: 8px;
        }
      }
    }
  }
}

/* 提价 */
.scoreEvaluation {
  min-height: 300px;
  background: #fff;
}

.answer-img {
  position: fixed;
  bottom: 200px;
  right: 16px;

  img {
    width: 96px;
    height: 96px;
  }
}

.reply-img {
  width: 120px !important;
  height: 120px !important;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
}

.coupon-content {
  padding: 20px 32px;

  .coupon-title {
    margin: 0px 0px 26px;
    display: flex;
    align-items: center;

    .rate {
      margin-left: 12px;

      /deep/ .el-rate {
        height: 100%;

        .el-rate__icon {
          font-size: 22px;
        }
      }
    }
  }

  .keep-learn {
    height: 40px;
    line-height: 38px;
    padding: 0px 28px;
    margin: 24px 0px 18px;
    float: right;
  }
}

.uploadBpx {
  margin-top: 12px;

  /deep/ .el-upload-list__item {
    width: 60px;
    height: 60px;
  }

  /deep/ .el-upload--picture-card {
    width: 60px;
    height: 60px;
    line-height: 58px;
    background: #f7f7f7;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #dddddd;
  }

  .tips {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #999999;
    line-height: 14px;
    margin-left: 8px;

    .num {
      color: #ff5e51;
    }
  }
}

.keep-learn {
  height: 40px;
  line-height: 38px;
  padding: 0px 28px;
  margin: 24px 0px 18px;
  float: right;
}

.pagination {
  padding-bottom: 20px;
}

/deep/.el-textarea {
  border: 1px solid #fe1313;

  .el-textarea__inner {
    background: #f5f7f9;
    border-radius: 8px 8px 8px 8px;
    border: none;
  }
}</style>
